/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getImage, GatsbyImage} from 'gatsby-plugin-image';
import DoctorPageTemplate from "../../templates/doctor-page";
import InfoBox from "../../components/info-box";
import PortraitSalamon from "../../components/portraits/portrait-salamon";
const MDXLayout = ({children}) => {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents());
  return React.createElement(DoctorPageTemplate, {
    title: "dr. med. univ. Bud. Thomas Salamon",
    subline: React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Facharzt für Psychiatrie und Psychotherapie"), React.createElement(_components.p, null, "Facharzt für Psychosomatische Medizin und Psychotherapie")),
    portrait: React.createElement(PortraitSalamon)
  }, children);
};
function _createMdxContent(props) {
  return React.createElement(React.Fragment, null, React.createElement(InfoBox, {
    title: "Tiefenpsychologisch fundierte Psychotherapie"
  }), "\n", React.createElement(InfoBox, {
    title: "Einzelbehandlung und Gruppe"
  }), "\n", React.createElement(InfoBox, {
    title: "Zertifikat Operationalisierte Psychodynamische Diagnostik"
  }), "\n", React.createElement(InfoBox, {
    title: "Psychoonkologie"
  }), "\n", React.createElement(InfoBox, {
    title: "Facharzt für Psychiatrie und Psychotherapie"
  }), "\n", React.createElement(InfoBox, {
    title: "Facharzt für Psychosomatische Medizin und Psychotherapie"
  }));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
