import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const PortraitSchroeter = () => {
  const {
    portrait: {
      childImageSharp: { gatsbyImageData },
    },
  } = useStaticQuery(query);

  return (
    <GatsbyImage
      image={gatsbyImageData}
      alt="Portrait dr. med. univ. Bud. Thomas Salamon"
      className="h-full"
    />
  );
};

const query = graphql`
  query ProtraitSchroeterQuery {
    portrait: file(relativePath: { eq: "herr-dr-salamon.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
  }
`;

export default PortraitSchroeter;
